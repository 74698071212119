// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "trix"
import "@rails/actiontext"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

require("jquery")

$( document ).ready(function() {
  // ON DOCUMENT READY
});

$(document).on('click', '.mobile_menu', function(e){
  $('.bar1, .bar2, .bar3').toggleClass("change");
  $(".navigation_links").toggleClass("visible", 1000);
  // $(".navigation_links").slideToggle(300);
});